import React, { useContext, useEffect, useState } from 'react';
import { InvestorDatabasePaginatedCollectionResponse } from '/../libs/shared-types/src/types/view/InvestorDiscoveryView';
import { Link } from 'react-router-dom';
import InvestorDatabaseTable from './InvestorDatabaseTable';
import Alert from '/src/components/notifications/Alert';
import { STARTUP_ACCESS_MANAGER_ROUTE } from '/src/constants/Routes';
import Tooltip from '/src/components/utility/Tooltip';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import SearchBar from '/src/components/inputs/SearchBar';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import DropdownMultiCheckBoxes from '/src/components/DropdownMultiCheckBoxes';
import {
  enumToList,
  enumToSelectOptions,
} from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import { HowOftenType } from '/../libs/shared-types/src/constants/HowOftenType';
import {
  ArrowLongUpIcon,
  CurrencyDollarIcon,
  QueueListIcon,
} from '@heroicons/react/20/solid';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import {
  addInvestorsToPipeline,
  addManyInvestorToTargetList,
  fetchActiveRound,
} from '/src/services/startupService';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import AddInvestorToListsDialog from '../../investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import FieldFilterWrapper from '../../../components/FieldFilterWrapper';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';

function getTableHeaders(canAccessFitScore: boolean) {
  const tableHeaders = [
    {
      sortKey: canAccessFitScore ? 'name' : undefined,
      element: <span>Name</span>,
    },
    {
      sortKey: canAccessFitScore ? 'type' : undefined,
      element: <span>Type</span>,
    },
    {
      sortKey: canAccessFitScore ? 'firm' : undefined,
      element: <span>Firm &amp; Role</span>,
    },
    {
      sortKey: canAccessFitScore ? 'fitScore' : undefined,
      element: (
        <span className="flex flex-row">
          Fit
          <Tooltip
            tooltipText={
              "Represents how well your company fits in the investor's thesis"
            }
            position="left"
            width="w-64"
          />
        </span>
      ),
    },
    {
      sortKey: canAccessFitScore ? 'checkRangeMin' : undefined,
      element: <span>Check Size</span>,
    },
    {
      sortKey: canAccessFitScore ? 'leadsRounds' : undefined,
      element: <span>Leads Rounds</span>,
    },
    {
      sortKey: canAccessFitScore ? 'isOpenToColdInbound' : undefined,
      element: <span>Cold Inbound</span>,
    },
    { element: <span className="sr-only">Actions</span> },
  ];
  return tableHeaders;
}

interface InvestorDatabaseInvestorsProps {
  apiResponse?: InvestorDatabasePaginatedCollectionResponse;
  isSearchLoading: boolean;
  investorLists: InvestorList[];
  fetchInvestorLists: () => Promise<void>;
}

function InvestorDatabaseInvestors({
  apiResponse,
  isSearchLoading,
  investorLists,
  fetchInvestorLists,
}: InvestorDatabaseInvestorsProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [isAddingToPipeline, setIsAddingToPipeline] = useState(false);
  const [isAddingToTargetList, setIsAddingToTargetList] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetSelectedTrigger, setResetSelectedTrigger] = useState(false);
  const queryClient = useQueryClient();

  const canAccessFitScore = hasFeatureAccess(
    StartupPaidFeatures.InvestorFitScore,
    subscriptionTier,
  );

  const invalidateInvestorListData = (investorListIds: string[]) => {
    for (const investorListId of investorListIds) {
      queryClient.invalidateQueries({
        queryKey: [INVESTOR_LIST_BY_ID_KEY, investorListId],
      });
    }
  };

  useEffect(() => {
    if (!canAccessFitScore && apiResponse?.page) {
      resetSelectedRows();
    }
  }, [apiResponse?.page]);

  if (!apiResponse) {
    return <></>;
  }

  const handleAddInvestorToPipeline = async () => {
    const currentRound = fetchActiveRound();
    if (!currentRound) {
      setToastConfig({
        title: 'You do not have an Investor Pipeline',
        message: 'Open a round to create an Investor Pipeline.',
        isError: true,
      });
      return;
    }

    if (!apiResponse) {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
      return;
    }

    setIsAddingToPipeline(true);
    const investors = selectedRows.map((investorId: string) => ({
      investorId: apiResponse.results.find(
        (investor) => investor._id === investorId,
      )!._id,
      investorDataType: apiResponse.results.find(
        (investor) => investor._id === investorId,
      )!.investorDataType,
    }));
    const { isSuccess, error } = await addInvestorsToPipeline(investors);
    if (isSuccess) {
      setToastConfig({
        isError: false,
        message: 'Investors added to pipeline',
      });
      resetSelectedRows();
    } else {
      setToastConfig({
        title: ERROR,
        message: error,
        isError: true,
      });
    }
    setIsAddingToPipeline(false);
  };

  const handleAddInvestorToTargetList = async (investorListIds: string[]) => {
    const investorsData = apiResponse.results
      .filter((investor) => selectedRows.includes(investor._id))
      .map((populatedInvestor) => ({
        investorId: populatedInvestor._id,
        investorDataType: populatedInvestor.investorDataType,
      }));
    setIsAddingToTargetList(true);
    const { isSuccess } = await addManyInvestorToTargetList({
      investors: investorsData,
      investorListIds,
    });
    setIsAddingToTargetList(false);
    if (isSuccess) {
      setToastConfig({
        isError: false,
        message: 'Investors added to target lists',
      });
      resetSelectedRows();
    } else {
      setToastConfig({
        title: ERROR,
        message: 'Please refresh the page and try again.',
        isError: true,
      });
    }
    setIsModalOpen(false);
    fetchInvestorLists();
    invalidateInvestorListData(investorListIds);
  };

  const resetSelectedRows = () => {
    setResetSelectedTrigger((prev) => !prev);
  };

  return (
    <>
      {apiResponse.totalCount === 0 &&
        apiResponse.filter === '' &&
        !Object.keys(apiResponse.fieldFilters).length && (
          <Alert
            alertType="Info"
            color="blue"
            content={
              <p>
                Hmm... it looks like there are no investors here right now.
                Check back later to see an updated list, or head to your&nbsp;
                <Link
                  to={STARTUP_ACCESS_MANAGER_ROUTE}
                  className="hyperlink font-bold underline"
                >
                  Access Manager
                </Link>
                &nbsp;to see investors who already have access to your startup.
              </p>
            }
            canDismiss={false}
            isShown
          />
        )}

      {(apiResponse.totalCount > 0 ||
        apiResponse.filter !== '' ||
        Object.keys(apiResponse.fieldFilters).length > 0) && (
        <>
          <div className="mb-3 flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
            <div className="relative flex items-center">
              <SearchBar
                shouldUpdateUrlParams
                isDebounce
                placeholder="Search by name, type or firm"
                initialValue={apiResponse.filter}
                isDisabled={!canAccessFitScore}
              />
              {isSearchLoading && (
                <LoadingSpinner
                  color="blue"
                  className="absolute right-[-2rem]"
                />
              )}
            </div>
            {!!selectedRows.length && (
              <div className="flex-grow space-x-3">
                <button
                  type="button"
                  className="app-button--neutral"
                  onClick={handleAddInvestorToPipeline}
                  disabled={isAddingToPipeline}
                >
                  <CurrencyDollarIcon className="mr-2 h-5 w-5" />
                  Add to Pipeline
                </button>
                <button
                  type="button"
                  className="app-button--neutral"
                  onClick={() => setIsModalOpen(true)}
                  disabled={isAddingToTargetList}
                >
                  <div className="relative mr-2">
                    <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                    <ArrowLongUpIcon
                      className="absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white text-gray-700"
                      aria-hidden="true"
                    />
                  </div>
                  Add to Target Lists
                </button>
              </div>
            )}
            <FieldFilterWrapper>
              {(updateParams) => (
                <div className="flex flex-row items-center space-x-2">
                  <span className="text-sm text-gray-600">Filters:</span>
                  <DropdownMultiCheckBoxes
                    key={apiResponse.fieldFilters?.investorTypes}
                    label="Type"
                    disabled={!canAccessFitScore}
                    checkboxes={enumToList(InvestorTypes).map((x) => ({
                      label: x,
                      value:
                        apiResponse.fieldFilters.investorTypes?.includes(x) ??
                        false,
                    }))}
                    onCheckboxesChange={(checkboxStates) => {
                      updateParams({
                        ...apiResponse.fieldFilters,
                        investorTypes: Object.keys(checkboxStates).filter(
                          (key) => checkboxStates[key],
                        ),
                      });
                    }}
                  />

                  <DropdownMultiCheckBoxes
                    key={apiResponse.fieldFilters?.roundStages}
                    label="Stage"
                    disabled={!canAccessFitScore}
                    checkboxes={enumToList(RoundStages).map((x) => ({
                      label: x,
                      value:
                        apiResponse.fieldFilters.roundStages?.includes(x) ??
                        false,
                    }))}
                    onCheckboxesChange={(checkboxStates) => {
                      updateParams({
                        ...apiResponse.fieldFilters,
                        roundStages: Object.keys(checkboxStates).filter(
                          (key) => checkboxStates[key],
                        ),
                      });
                    }}
                  />

                  <DropdownMultiCheckBoxes
                    key={apiResponse.fieldFilters?.leadsRounds}
                    label="Leads"
                    disabled={!canAccessFitScore}
                    checkboxes={enumToSelectOptions(HowOftenType).map((x) => ({
                      label: x.label,
                      value:
                        apiResponse.fieldFilters.leadsRounds?.includes(
                          x.value,
                        ) ?? false,
                    }))}
                    onCheckboxesChange={(checkboxStates) => {
                      updateParams({
                        ...apiResponse.fieldFilters,
                        leadsRounds: Object.keys(checkboxStates).filter(
                          (key) => checkboxStates[key],
                        ),
                      });
                    }}
                  />
                </div>
              )}
            </FieldFilterWrapper>
          </div>
          <InvestorDatabaseTable
            headers={getTableHeaders(canAccessFitScore)}
            parentPage={apiResponse.page}
            parentTotalCount={apiResponse.totalCount}
            parentSortOrder={apiResponse.sortOrder}
            parentSortedColumn={apiResponse.sortKey}
            parentFilter={apiResponse.filter}
            parentPerPage={apiResponse.perPage}
            parentTotalPages={apiResponse.totalPages}
            investors={apiResponse.results}
            fitScoreStats={apiResponse.stats}
            hasFitScoreAccess={canAccessFitScore}
            blurRowsAfterIndex={!canAccessFitScore ? 9 : undefined}
            investorLists={investorLists}
            fetchInvestorLists={fetchInvestorLists}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            resetSelectedTrigger={resetSelectedTrigger}
          />
        </>
      )}

      <ModalWrapper open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddInvestorToListsDialog
          investorLists={investorLists}
          onSave={handleAddInvestorToTargetList}
          onCancel={() => setIsModalOpen(false)}
        />
      </ModalWrapper>

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </>
  );
}

export default InvestorDatabaseInvestors;
