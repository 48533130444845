import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  StartupPaidFeatures,
  hasFeatureAccess,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { StartupGetPersons } from '/../libs/shared-types/src/constants/ApiRoutes';
import { toDashCase } from '/src/util/formatting/strings';
import TabsHeader from '/src/components/tabs/TabsHeader';
import {
  ComparablePersonView,
  PersonsPaginatedCollectionResponse,
} from '../../../types/view/PersonView';
import MyNetworkPersons from './MyNetworkPersons';
import useUrlParams from '/src/hooks/useUrlParams';
import { removeUndefinedOrNullProperties } from '/src/util/removeUndefinedOrNullProperties';

export const defaultSortKeyPersons = 'name' as keyof ComparablePersonView;

function MyNetworkRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [personResponse, setPersonResponse] =
    useState<PersonsPaginatedCollectionResponse>();
  const [isRefetching, setIsRefetching] = useState(false);

  const { subscriptionTier } = useContext(AccountMetadataContext);

  const { getParam } = useUrlParams();
  const fieldFilters = getParam('fieldFilters');
  const filter = getParam('filter');
  const page = getParam('page');
  const perPage = getParam('perPage');
  const sortKey = getParam('sortKey');
  const sortOrder = getParam('sortOrder');

  const isFirstLoading = useRef<boolean>(true);

  const tabs = [
    {
      name: 'Imported Connections',
      stat: personResponse?.totalCount ?? 0,
    },
  ];

  function toggleIsRefetching() {
    setIsRefetching((prev) => !prev);
  }

  async function fetchPersons(args: Partial<any>) {
    if (
      subscriptionTier &&
      !hasFeatureAccess(StartupPaidFeatures.Persons, subscriptionTier)
    ) {
      return;
    }

    try {
      if (isFirstLoading.current) {
        setIsLoading(true);
        isFirstLoading.current = false;
      }
      setIsSearchLoading(true);

      const cleanParam = removeUndefinedOrNullProperties(args);

      const url = StartupGetPersons.buildEndpoint(undefined, {
        ...cleanParam,
        fieldFilters: args?.fieldFilters
          ? JSON.parse(args.fieldFilters)
          : undefined,
      });

      const data = await API.get<PersonsPaginatedCollectionResponse>(url);
      setPersonResponse(data);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsSearchLoading(false);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPersons({
      fieldFilters,
      filter,
      page,
      perPage,
      sortKey,
      sortOrder,
    });
    return () => {
      isFirstLoading.current = false;
    };
  }, [fieldFilters, page, perPage, sortKey, sortOrder, filter, isRefetching]);

  return (
    <main>
      <header>
        <h2 className="page-title">My Network</h2>
        <TabsHeader tabs={tabs} />
      </header>

      <section className="my-4">
        {isLoading && (
          <PageLoadingSpinner message="Loading your fundraising network... 💰" />
        )}

        {!isLoading && (
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <MyNetworkPersons
                      apiResponse={personResponse}
                      isSearchLoading={isSearchLoading}
                      refetch={toggleIsRefetching}
                    />
                  }
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        )}
      </section>
    </main>
  );
}

export default MyNetworkRoute;
