export enum RoutePrefix {
  Admin = 'admin',
  Ai = 'ai',
  Authenticated = 'authenticated',
  Cognito = 'cognito',
  Csv = 'csv',
  DataRoom = 'data-room',
  DealViewSession = 'deal-view-session',
  Internal = 'internal',
  Investor = 'investor',
  InvestorFragments = 'investor-fragments',
  InvestorLists = 'investor-lists',
  InvestorPipeline = 'investor-pipeline',
  Relationships = 'relationships',
  LinkSharing = 'link-sharing',
  Persons = 'persons',
  Public = 'public',
  Requests = 'requests',
  Shared = 'shared',
  Startup = 'startup',
  Subscription = 'subscription',
  UnconfirmedUser = 'unconfirmed-user',
  Visitor = 'visitor',
  Zendesk = 'zendesk',
}

class RouteBuilder {
  prefixes: RoutePrefix[];
  endpoint: string;
  slug?: string;

  constructor(prefixes: RoutePrefix[], endpoint: string, slug?: string) {
    this.prefixes = prefixes;
    this.endpoint = endpoint;
    this.slug = slug;
  }

  buildApiRoute() {
    if (!this.slug) {
      return `/${this.endpoint}`;
    }

    const slug = this.slug ? `:${this.slug}` : '';
    if (!this.endpoint) {
      return `/${slug}`;
    }

    return `/${this.endpoint}/${slug}`;
  }

  buildEndpoint(slugValue?: string, queryParams?: Record<string, any>) {
    if (queryParams) {
      delete queryParams['results'];
      queryParams['fieldFilters'] = JSON.stringify(queryParams['fieldFilters']);
      for (let key in queryParams) {
        if (
          queryParams[key] === undefined ||
          queryParams[key] === 'undefined'
        ) {
          delete queryParams[key];
        }
      }
    }
    const params = new URLSearchParams(queryParams);
    const paramString =
      queryParams && Object.keys(queryParams).length > 0
        ? `?${params.toString()}`
        : '';
    const formattedPrefixes = this.prefixes.join('/');
    return `/${formattedPrefixes}/${this.endpoint}${
      slugValue ? `/${slugValue}` : ''
    }${paramString}`;
  }
}

export const CognitoCreateNewStartup = new RouteBuilder(
  [RoutePrefix.Cognito],
  'new-startup'
);

export const CognitoCreateNewInvestor = new RouteBuilder(
  [RoutePrefix.Cognito],
  'new-investor'
);

export const CognitoUploadPublic = new RouteBuilder(
  [RoutePrefix.Cognito],
  'upload-public'
);

export const CognitoUploadPrivate = new RouteBuilder(
  [RoutePrefix.Cognito],
  'upload-private'
);

export const CognitoGetSignedUrl = new RouteBuilder(
  [RoutePrefix.Cognito],
  'get-signed-url'
);

export const PublicGoogleCognitoTokenExchange = new RouteBuilder(
  [RoutePrefix.Public],
  'token-exchange',
  'code'
);

export const PublicLogIn = new RouteBuilder(
  [RoutePrefix.Public],
  'user',
  'email'
);

export const PublicGetPublicStartup = new RouteBuilder(
  [RoutePrefix.Public],
  'public-startup',
  'id'
);

export const PublicGetPublicInvestor = new RouteBuilder(
  [RoutePrefix.Public],
  'public-investor',
  'flowlieToken'
);
export const PublicGetPublicInvestorList = new RouteBuilder(
  [RoutePrefix.Public],
  'public-investor-list',
  'shareSlug'
);

export const PublicVerifyHumanity = new RouteBuilder(
  [RoutePrefix.Public],
  'unconfirmed-user/verify-humanity'
);

export const PublicCreateNewUnconfirmedUser = new RouteBuilder(
  [RoutePrefix.Public],
  'unconfirmed-user/new'
);

export const PublicUpdateUnconfirmedUser = new RouteBuilder(
  [RoutePrefix.Public],
  'unconfirmed-user/update'
);

export const PublicGetUnconfirmedUser = new RouteBuilder(
  [RoutePrefix.Public],
  'unconfirmed-user/email',
  'email'
);

export const PublicUpdateDealViewSession = new RouteBuilder(
  [RoutePrefix.Public],
  'deal-view-session/update'
);

export const PublicCreateNewDealViewSession = new RouteBuilder(
  [RoutePrefix.Public],
  'deal-view-session/new'
);

export const PublicVerifyDataRoomPassword = new RouteBuilder(
  [RoutePrefix.Public],
  'data-room/verify-password'
);

export const PublicGetShareTokenMetadata = new RouteBuilder(
  [RoutePrefix.Public],
  'general',
  'token'
);

export const PublicGetStartupDeck = new RouteBuilder(
  [RoutePrefix.Public],
  'get-public-startup-deck',
  'startupId'
);

export const PublicFlowlinkMetadata = new RouteBuilder(
  [RoutePrefix.Public],
  'flowlink/metadata',
  'token'
);

export const PublicDealShareMetadata = new RouteBuilder(
  [RoutePrefix.Public],
  'dealshare/metadata',
  'token'
);

export const InternalCloseRoundCron = new RouteBuilder(
  [RoutePrefix.Internal],
  'close-round-cron'
);

export const InternalSendDeckFeedbackEmail = new RouteBuilder(
  [RoutePrefix.Internal],
  'deck-feedback-email'
);

export const VisitorGetPublicStartup = new RouteBuilder(
  [RoutePrefix.Visitor],
  'public-startup'
);

export const VisitorVerifyDealViewSession = new RouteBuilder(
  [RoutePrefix.Visitor],
  'verify-session'
);

export const VisitorGetStartupDeck = new RouteBuilder(
  [RoutePrefix.Visitor],
  'get-startup-deck'
);

export const AdminGetUsersConnections = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Admin],
  'users-connections'
);
export const AdminGetFitScoreBenchmark = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Admin],
  'fit-score-benchmark'
);

export const AdminGetUnverifiedInvestorById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Admin],
  'unverified-investor/id',
  'id'
);
export const AdminCreateOrUpdateUnverifiedInvestor = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Admin],
  'unverified-investor'
);
export const AdminUpdateUnverifiedInvestorById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Admin],
  'update-unverified-investor',
  'id'
);

export const StartupGet = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  ''
);

export const StartupGetOnePagerPreview = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'preview'
);

export const StartupGetById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'id',
  'id'
);

export const StartupGetByDealShareToken = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.LinkSharing],
  'dealshare-token',
  'token'
);

export const StartupUpdate = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'update'
);

export const StartupUpdateDeck = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'update-deck'
);

export const StartupInvestorDiscovery = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'investor-discovery'
);

// Investor Lists
export const StartupGetInvestorLists = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  ''
);
export const StartupGetInvestorListById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  '',
  'id'
);
export const StartupCreateInvestorList = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  ''
);
export const StartupUpdateInvestorList = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  '',
  'id'
);
export const StartupUpdateInvestorListShareSetting = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  'share-setting',
  'id'
);
export const StartupUpdateInvestorListResetShareLink = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  'reset-share-link',
  'id'
);
export const StartupDeleteInvestorList = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  '',
  'id'
);
// TODO: refactor routes so that the listId is the slug, followed by the 'investor-add'
export const StartupAddInvestorsToLists = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  'investor'
);
export const StartupAddInvestorToLists = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  'investor-add'
);
export const StartupUpdateInvestorPriority = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  'investor-priority'
);
export const StartupRemoveInvestorFromInvestorList = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  'investor-remove'
);

export const StartupInvestorListSearchInvestors = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.InvestorLists],
  'search-investors',
  'id'
);

export const StartupInvestorAccess = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'investor-access'
);

export const StartupInvestorAccessActivity = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'investor-access/activity',
  'id'
);

export const StartupInvestorAccessSharingData = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'investor-access/sharing-data',
  'id'
);

export const StartupGetReferralsStats = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'referrals/stats'
);

export const StartupGetReferrals = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'referrals'
);

export const StartupRequestDeckFeedback = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'deck-feedback/request'
);

export const StartupGetCurrentRound = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'round/current'
);

export const StartupGetCurrentRoundById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'round/current',
  'id'
);

export const StartupGetRoundHistory = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'round/history'
);

export const StartupGetRoundHistoryById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'round/history',
  'id'
);

export const StartupNewRound = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'round/new'
);

export const StartupCloseRound = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'round/close'
);

export const StartupUpdateRound = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'round/update'
);

export const StartupUpdateLegal = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'legal/update'
);

export const StartupGetUserActivationTasks = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'user-activation/tasks'
);

export const StartupUserActivationTours = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'user-activation/tours'
);

export const StartupGetDealViewSessions = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'deal-view-session/view-sessions'
);

export const StartupGetDashboard = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'dashboard'
);

export const StartupGetDataRoom = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  RoutePrefix.DataRoom
);
export const StartupUpdateDataRoomChecklist = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.DataRoom],
  'checklist'
);
export const StartupUpdateDataRoomLink = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.DataRoom],
  'link'
);
export const StartupDeleteDataRoomLink = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  RoutePrefix.DataRoom + '/link'
);
export const StartupVerifyDataRoomPassword = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  RoutePrefix.DataRoom + '/verify-password'
);

export const StartupInvestorPipeline = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorPipeline,
  ],
  ''
);

export const StartupInvestorPipelineAddInvestors = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorPipeline,
  ],
  'investors-add'
);

export const StartupInvestorPipelineRemoveInvestor = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorPipeline,
  ],
  'investor-remove'
);

export const StartupInvestorPipelineUpdateInvestor = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorPipeline,
  ],
  'investor-update'
);

export const StartupInvestorPipelineStages = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorPipeline,
  ],
  'stages'
);

export const StartupInvestorPipelineSearchInvestors = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorPipeline,
  ],
  'search-investors'
);

export const StartupRelationshipUpdate = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Relationships],
  'update'
);

export const StartupGetInvestorFragmentById = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorFragments,
  ],
  'id',
  'id'
);

export const StartupGetInvestorFragments = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorFragments,
  ],
  'get-all'
);
export const StartupAddOrUpdateInvestorFragment = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorFragments,
  ],
  'update'
);

export const StartupDeleteInvestorFragment = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Startup,
    RoutePrefix.InvestorFragments,
  ],
  'delete',
  'id'
);

export const StartupGetPersonById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Persons],
  'id',
  'id'
);

export const StartupGetPersons = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Persons],
  'get-all' // TODO:  clean up this route
);
export const StartupAddOrUpdatePerson = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Persons],
  'update'
);

export const StartupDeletePerson = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Persons],
  'delete',
  'id'
);

export const StartupGetDeckVersionHistory = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup],
  'deck-version-history'
);

export const StartupGetViewSessionsCsv = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Csv],
  'view-sessions'
);

export const StartupGetInvestorsEngagedCsv = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Csv],
  'investors-engaged'
);

export const StartupGetDescriptionFeedback = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Ai],
  'description-feedback'
);

export const StartupGetIcpFeedback = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Startup, RoutePrefix.Ai],
  'icp-feedback'
);

export const InvestorCreateNewDealViewSession = new RouteBuilder(
  [
    RoutePrefix.Authenticated,
    RoutePrefix.Investor,
    RoutePrefix.DealViewSession,
  ],
  'new'
);

export const InvestorGet = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  ''
);

export const InvestorShareDeal = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'share'
);

export const InvestorUpdate = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'update'
);

export const InvestorUpdateLegal = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'legal/update'
);

export const InvestorGetConnections = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'connections'
);

export const InvestorInviteConnections = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'connections/invite'
);

export const InvestorGetConnectionMatches = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'connections/matches',
  'startupId'
);

export const InvestorGetDealById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'deal',
  'id'
);

export const InvestorGetReferralsStats = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'referrals/stats'
);

export const InvestorGetReferrals = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'referrals'
);

export const InvestorDealflowActive = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'active'
);

export const InvestorDealflowScreening = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'screening'
);

export const InvestorDealflowPassed = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'passed'
);

export const InvestorDealflowPortfolio = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'portfolio'
);

export const InvestorUpdateDealStatus = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'deals/status'
);

export const InvestorUpdateDealActiveStage = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'deals/update-active-stage'
);

export const InvestorUpdateDealRating = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'deals/update-rating'
);

export const InvestorUpdateDealPriority = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'deals/update-priority'
);

export const InvestorEmailInvestmentUpdates = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'email-investment-updates'
);

export const InvestorEmailRequestRoundDetails = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'email-request-round-details'
);

export const InvestorDealflowActiveBoardStages = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'dealflow/active-board-stages'
);

export const InvestorUserActivationTasks = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'user-activation/tasks'
);

export const InvestorUserActivationTours = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor],
  'user-activation/tours'
);

export const InvestorRequestsIncoming = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor, RoutePrefix.Requests],
  'incoming',
  'type'
);

export const InvestorRequestsOutgoing = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor, RoutePrefix.Requests],
  'outgoing',
  'type'
);

export const InvestorRequestsDealPreview = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor, RoutePrefix.Requests],
  'deal-preview',
  'requestId'
);

export const InvestorRequestsAccept = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor, RoutePrefix.Requests],
  'accept'
);

export const InvestorRequestsReject = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor, RoutePrefix.Requests],
  'reject'
);

export const InvestorRequestsCancel = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor, RoutePrefix.Requests],
  'cancel'
);

export const InvestorRequestsNew = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Investor, RoutePrefix.Requests],
  'new'
);

export const SharedUpdatePublic = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared],
  'update-public',
  'key'
);

export const SharedDealShareMetadata = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared],
  'dealshare/metadata',
  'token'
);

export const SharedFlowlinkMetadata = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared],
  'flowlink/metadata',
  'token'
);

export const SharedCompleteActivationTask = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared],
  'complete-activation-task'
);

export const SharedGetInvestorById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared],
  'investor/id',
  'id'
);

export const SharedGetUnverifiedInvestorById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared],
  'unverified-investor/id',
  'id'
);

export const SharedGetStartupDeckById = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared],
  'get-startup-deck',
  'startupId'
);

export const SharedGetSubscription = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.Subscription],
  'get-subscription'
);

export const SharedLinkSharingGetFlowlink = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.LinkSharing],
  'flowlink'
);

export const SharedLinkSharingResetFlowlink = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.LinkSharing],
  'flowlink/reset'
);

export const SharedLinkSharingGetDealShareLink = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.LinkSharing],
  'dealshare',
  'startupId'
);

export const SharedLinkSharingConsumeFlowlink = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.LinkSharing],
  'consume/flowlink'
);

export const SharedLinkSharingFlowlinkPrivacySetting = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.LinkSharing],
  'flowlink/set-privacy'
);

export const SharedGetUnconfirmedUser = new RouteBuilder(
  [RoutePrefix.Shared, RoutePrefix.UnconfirmedUser],
  'email',
  'email'
);

export const SharedCompleteUnconfirmedUser = new RouteBuilder(
  [RoutePrefix.Shared, RoutePrefix.UnconfirmedUser],
  'complete',
  'email'
);

export const SharedZendeskNewSupportRequest = new RouteBuilder(
  [RoutePrefix.Authenticated, RoutePrefix.Shared, RoutePrefix.Zendesk],
  'new-support-request'
);
