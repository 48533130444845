import React, { useContext, useState } from 'react';
import { Route, useParams, Routes, Navigate } from 'react-router-dom';
import { InvestorFragmentView } from '/../libs/shared-types/src/types/view/InvestorFragmentView';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import TabsHeader from '/src/components/tabs/TabsHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  StartupGetInvestorFragmentById,
  StartupAddInvestorToLists,
  StartupGetInvestorLists,
  StartupInvestorAccessActivity,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import InvestorDetailHeader from '../../investor/InvestorDetail/InvestorDetailHeader';
import {
  QueueListIcon,
  ArrowLongUpIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import AddInvestorToListsDialog from '../../investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { useInvestorDatabaseContext } from '../../startup/InvestorDatabase/InvestorDatabaseContext';
import InvestorFragmentDetailAboutCard from './InvestorFragmentDetailAboutCard';
import ImportInvestorDialog from '../../startup/InvestorDatabase/ImportInvestorDialog';
import InvestorActivityTab from '../../investor/InvestorDetail/tabs/InvestorActivityTab';
import { InvestorActivityTabView } from '../../../types/view/InvestorActivityTabView';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';

const tabs = [{ name: 'Profile' }, { name: 'Activity' }];

function InvestorFragmentDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [investor, setInvestor] = useState<InvestorFragmentView>();
  const { addInvestorToPipeline } = useInvestorDatabaseContext();
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [aggregateInvestorActivity, setAggregateInvestorActivity] =
    useState<InvestorActivityTabView>();

  const canAccessFitScore = hasFeatureAccess(
    StartupPaidFeatures.InvestorFitScore,
    subscriptionTier,
  );

  const [investorLists, setInvestorLists] = useState<InvestorList[]>([]);
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'edit' | 'lists';
    isModalOpen: boolean;
  }>({
    type: '',
    isModalOpen: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const queryClient = useQueryClient();

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false });
    setErrorMessage('');
  }

  async function fetchInvestorLists() {
    try {
      const data = await API.get<InvestorList[]>(
        StartupGetInvestorLists.buildEndpoint(),
      );
      setInvestorLists(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function updateListsWithInvestor(
    investorId: string,
    investorListIds: string[],
  ) {
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorId,
        investorDataType: InvestorDataType.Fragment,
        investorListIds: investorListIds,
      });
      fetchInvestorLists();
      closeModal();
      invalidateInvestorListDetailsData(investorListIds);
    } catch (error) {
      Logger.error(error);
    }
  }

  async function fetchData(isRefreshing = false) {
    if (id === undefined) {
      return;
    }

    setIsLoading(!isRefreshing);

    try {
      const investorData = await API.get<InvestorFragmentView>(
        StartupGetInvestorFragmentById.buildEndpoint(id),
      );
      setInvestor(investorData);
      fetchInvestorLists();
      const investorActivityData: InvestorActivityTabView = await API.get(
        StartupInvestorAccessActivity.buildEndpoint(id, {
          investorType: investorData?.investorDataType,
        }),
      );
      setAggregateInvestorActivity(investorActivityData);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const invalidateInvestorListDetailsData = (investorListIds: string[]) => {
    for (const investorListId of investorListIds) {
      queryClient.invalidateQueries({
        queryKey: [INVESTOR_LIST_BY_ID_KEY, investorListId],
      });
    }
  };

  // we listen for url changes and re-fetch data
  // if we get a new id
  React.useEffect(() => {
    fetchData();
  }, [id]);

  if (isLoading && !aggregateInvestorActivity) {
    return <PageLoadingSpinner message="Loading a valued investor 🤝" />;
  }

  if (!investor) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          <h3>Oops! The investor you are looking for doesn&apos;t exist.</h3>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="pb-8">
        <header className="sticky top-0 z-10 bg-gray-100 sm:pt-6">
          <div className="relative mx-auto max-w-full lg:flex lg:items-center lg:justify-between lg:space-x-5">
            <InvestorDetailHeader
              hasFitScoreAccess={canAccessFitScore}
              investor={investor as any}
              fetchInvestor={fetchData}
            />
            <div className="flex space-x-3">
              <button
                className="app-button--neutral mt-3 lg:mt-0"
                onClick={() =>
                  setModalConfig({ type: 'lists', isModalOpen: true })
                }
              >
                <div className="relative mr-2">
                  <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                  <ArrowLongUpIcon
                    className="absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white"
                    aria-hidden="true"
                  />
                </div>
                Add to Target Lists
              </button>
              {investor.pipelineCurrentStage === undefined && (
                <button
                  className="app-button--primary mt-3 lg:mt-0"
                  onClick={async () => {
                    await addInvestorToPipeline(
                      investor._id,
                      InvestorDataType.Fragment,
                      undefined,
                    );
                    await fetchData(true);
                  }}
                >
                  <div className="relative mr-2">
                    <CurrencyDollarIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  Add to Pipeline
                </button>
              )}
            </div>
          </div>

          <TabsHeader tabs={tabs} />
        </header>
        <div className="px-1">
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <main className="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
                      <div className="flex flex-1 flex-col">
                        <div className="mt-4">
                          <InvestorFragmentDetailAboutCard
                            investor={investor}
                            onEditClick={() =>
                              setModalConfig({
                                type: 'edit',
                                isModalOpen: true,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col"></div>
                    </main>
                  }
                />
              ))}

              {aggregateInvestorActivity && investor?._id && (
                <Route
                  path={`${toDashCase(tabs[1].name)}/*`}
                  element={
                    <InvestorActivityTab
                      aggregateInvestorActivity={aggregateInvestorActivity}
                      investorId={investor._id}
                      investorName={
                        investor.firstName + ' ' + investor.lastName
                      }
                      investorDataType={investor.investorDataType}
                      fetchInvestorData={fetchData}
                    />
                  }
                />
              )}
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        </div>
      </main>

      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'edit' && (
          <ImportInvestorDialog
            investor={investor}
            onCancel={() => closeModal()}
            onSave={() => {
              closeModal();
              fetchData(true);
            }}
          />
        )}
        {modalConfig.type === 'lists' && (
          <AddInvestorToListsDialog
            investorLists={investorLists}
            onCancel={() => closeModal()}
            onSave={updateListsWithInvestor}
            investorId={investor._id}
            investorName={`${investor.firstName} ${investor.lastName}`}
          />
        )}
      </ModalWrapper>
    </div>
  );
}

export default InvestorFragmentDetailRoute;
