import React, { useContext, useState } from 'react';
import { Route, useParams, Routes, Navigate, Link } from 'react-router-dom';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import {
  localStorageGetAccountEmail,
  localStorageGetAuthenticatedAccountType,
} from '/src/middleware/LocalStorage';
import { InvestorDetailView } from '/../libs/shared-types/src/types/view/InvestorDetailView';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import TabsHeader from '/src/components/tabs/TabsHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import InvestorDetailThesisCard from './cards/InvestorDetailThesisCard';
import InvestorDetailContactCard from './cards/InvestorDetailContactCard';
import InvestorDetailHeader from './InvestorDetailHeader';
import InvestorDetailAboutCard from './cards/InvestorDetailAboutCard';
import InvestorDetailValueAddCard from './cards/InvestorDetailValueAddCard';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import { NOT_FOUND_ROUTE, INVESTOR_PROFILE_ROUTE } from '/src/constants/Routes';
import {
  CurrencyDollarIcon,
  PencilSquareIcon,
  QueueListIcon,
} from '@heroicons/react/20/solid';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  SharedGetInvestorById,
  StartupAddInvestorToLists,
  StartupGetInvestorLists,
  StartupInvestorAccessActivity,
  StartupInvestorPipelineAddInvestors,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { ArrowLongUpIcon } from '@heroicons/react/24/solid';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import AddInvestorToListsDialog from '../ActiveDealflow/dialogs/AddInvestorToListsDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import InvestorDetailFitScoreCard from './cards/InvestorDetailFitScoreCard';
import InvestorActivityTab from './tabs/InvestorActivityTab';
import { InvestorActivityTabView } from '../../../types/view/InvestorActivityTabView';

const tabs = [{ name: 'Profile' }];

function InvestorDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [accountType] = useState(localStorageGetAuthenticatedAccountType());
  const [investor, setInvestor] = useState<InvestorDetailView>();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidInvestor, setIsValidInvestor] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  const canAccessFitScore = hasFeatureAccess(
    StartupPaidFeatures.InvestorFitScore,
    subscriptionTier,
  );
  const [investorLists, setInvestorLists] = useState<InvestorList[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aggregateInvestorActivity, setAggregateInvestorActivity] =
    useState<InvestorActivityTabView>();

  async function fetchInvestorLists() {
    try {
      const data = await API.get<InvestorList[]>(
        StartupGetInvestorLists.buildEndpoint(),
      );
      setInvestorLists(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function updateListsWithInvestor(
    investorId: string,
    investorListIds: string[],
  ) {
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorId,
        investorDataType: InvestorDataType.Verified,
        investorListIds: investorListIds,
      });
      setIsModalOpen(false);
      fetchInvestorLists();
    } catch (error) {
      Logger.error(error);
    }
  }

  async function addInvestorToPipeline(
    investorId: string,
    investorDataType: InvestorDataType,
  ) {
    try {
      await API.put(StartupInvestorPipelineAddInvestors.buildEndpoint(), {
        investors: [
          {
            investorId: investorId,
            investorDataType: investorDataType,
          },
        ],
      });
      setToastConfig({
        isError: false,
        message: 'Investor added to pipeline',
      });
    } catch (error: any) {
      Logger.error(error.message);
      setToastConfig({
        isError: true,
        message: error.message,
      });
    }
  }

  async function fetchData(isRefreshing = false) {
    if (id === undefined) {
      return;
    }

    setIsLoading(!isRefreshing);

    try {
      // if you are an investor who is not connected,
      // this will throw an error and jump to the catch statement
      const investorData = await API.get<InvestorDetailView>(
        SharedGetInvestorById.buildEndpoint(id),
      );
      setInvestor(investorData);
      setIsValidInvestor(true);

      // here we know you are an investor and connected (from above)
      if (accountType === AccountTypes.Investor) {
        setIsConnected(true);
      }

      // if you are a startup, to be "connected", this investor
      // must have access to your deal
      if (accountType === AccountTypes.Startup) {
        if (!tabs.some((tab) => tab.name === 'Activity')) {
          tabs.push({ name: 'Activity' });
        }

        fetchInvestorLists();
        setIsConnected(true);
        const investorActivityData: InvestorActivityTabView = await API.get(
          StartupInvestorAccessActivity.buildEndpoint(id, {
            investorType: investorData?.investorDataType,
          }),
        );
        setAggregateInvestorActivity(investorActivityData);
      }
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
      setJoyrideActivationTourState({
        ...joyrideActivationTourState,
        run: true,
      });
    }
  }

  // we listen for url changes and re-fetch data
  // if we get a new id
  React.useEffect(() => {
    fetchData();
  }, [id]);

  if (isLoading && !aggregateInvestorActivity) {
    return <PageLoadingSpinner message="Loading a valued investor 🤝" />;
  }

  if (!isValidInvestor) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          {accountType === AccountTypes.Investor && (
            <h3>
              Oops! The investor either doesn&apos;t exist or you&apos;re not
              connected with them.
            </h3>
          )}
          {accountType === AccountTypes.Startup && (
            <h3>Oops! The investor you are looking for doesn&apos;t exist.</h3>
          )}
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="pb-8">
        <header className="sticky top-0 z-10 bg-gray-100 sm:pt-6">
          <div className="relative mx-auto max-w-full lg:flex lg:items-center lg:justify-between lg:space-x-5">
            {investor && (
              <InvestorDetailHeader
                hasFitScoreAccess={canAccessFitScore}
                investor={investor}
                fetchInvestor={fetchData}
              />
            )}
            {investor?.email === localStorageGetAccountEmail() && (
              <Link
                className="app-button--neutral mt-3 lg:mt-0"
                to={INVESTOR_PROFILE_ROUTE}
              >
                <PencilSquareIcon className="mr-2 h-4 w-4" />
                Edit Profile
              </Link>
            )}
            {accountType === AccountTypes.Startup && investor && (
              <div className="flex space-x-3">
                <button
                  className="app-button--neutral mt-3 lg:mt-0"
                  onClick={() => setIsModalOpen(true)}
                >
                  <div className="relative mr-2">
                    <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                    <ArrowLongUpIcon
                      className="absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white"
                      aria-hidden="true"
                    />
                  </div>
                  Add to Target Lists
                </button>
                {investor.pipelineCurrentStage === undefined && (
                  <button
                    className="app-button--primary mt-3 lg:mt-0"
                    onClick={async () => {
                      await addInvestorToPipeline(
                        investor._id,
                        InvestorDataType.Verified,
                      );
                      await fetchData(true);
                    }}
                  >
                    <div className="relative mr-2">
                      <CurrencyDollarIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    Add to Pipeline
                  </button>
                )}
              </div>
            )}
          </div>

          <TabsHeader tabs={tabs} />
        </header>
        <div className="px-1">
          <>
            <Routes>
              {investor &&
                ['', tabs[0].name].map((path) => (
                  <Route
                    key={path}
                    path={toDashCase(path)}
                    element={
                      <main className="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
                        <div className="flex flex-1 flex-col">
                          <div className="joyride-investorDetailThesis mt-4">
                            <InvestorDetailThesisCard investor={investor} />
                          </div>
                          <div className="mt-4">
                            <InvestorDetailValueAddCard investor={investor} />
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col space-y-4 pt-4">
                          <InvestorDetailFitScoreCard investor={investor} />
                          <InvestorDetailAboutCard investor={investor} />
                          <InvestorDetailContactCard
                            investor={investor}
                            isConnected={isConnected}
                          />
                        </div>
                      </main>
                    }
                  />
                ))}
              {aggregateInvestorActivity && investor?._id && (
                <Route
                  path={`${toDashCase(tabs[1].name)}/*`}
                  element={
                    <InvestorActivityTab
                      aggregateInvestorActivity={aggregateInvestorActivity}
                      investorId={investor._id}
                      investorName={
                        investor.firstName + ' ' + investor.lastName
                      }
                      investorDataType={investor.investorDataType}
                      fetchInvestorData={fetchData}
                    />
                  }
                />
              )}
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        </div>
      </main>

      {investor && (
        <ModalWrapper open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <AddInvestorToListsDialog
            investorLists={investorLists}
            onSave={updateListsWithInvestor}
            investorId={investor._id}
            investorName={investor.firstName + ' ' + investor.lastName}
            onCancel={() => setIsModalOpen(false)}
          />
        </ModalWrapper>
      )}

      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </div>
  );
}

export default InvestorDetailRoute;
