import React from 'react';
import { StrengthTag } from '/src/util/generateTagCloud';
import {
  RELATIONSHIP_STRENGTH_OPTIONS,
  Strength,
} from '/../libs/shared-types/src/constants/Strength';

function InvestorCellRelationship({ strength }: { strength?: Strength }) {
  return (
    <div className="app-table-row-text">
      {strength ? (
        <StrengthTag
          strength={strength}
          options={RELATIONSHIP_STRENGTH_OPTIONS}
        />
      ) : (
        '-'
      )}
    </div>
  );
}

export default InvestorCellRelationship;
